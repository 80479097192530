import React, { useEffect, useState, useMemo } from 'react';
import services from 'services';
import { message } from "antd";

const useGetPostForEdit = () => {
  const [loading, setLoading] = useState(false);

  // get post by tourID + postID
  const getPostForEdit = async (param) => {
    try {
      setLoading(true);
      const response = await services.GetPostForEdit(param);
      if (response?.success) {
        const processedPost = {
          ...response?.result?.post,
          configs: JSON.parse(response?.result?.post?.configs),
          addons: response?.result?.post?.addons.map(addon => {
            return {
              ...addon,
              configs: JSON.parse(addon.configs)
            }
          }),
          author: response.result.author,
          profilePicture: response.result.profilePicture,
        }
        return processedPost
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  // get post by postID
  const getPostForEditByPostId = async (param) => {
    try {
      const response = await services.GetPostForEditByPostId(param);
      setLoading(true);
      if (response?.success) {
        const processedPost = {
          ...response?.result?.post,
          configs: JSON.parse(response?.result?.post?.configs),
          addons: response?.result?.post?.addons.map(addon => {
            return {
              ...addon,
              configs: JSON.parse(addon.configs)
            }
          }),
          author: response.result.author,
          profilePicture: response.result.profilePicture,
        }
        return processedPost
      }
    } catch (error) {
      message.config({ maxCount: 1 })
      message.error('You not have permission to see this post!')
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    getPostForEdit,
    getPostForEditByPostId
  };
};

export default useGetPostForEdit;
