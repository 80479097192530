import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Row, Col } from 'antd';
import customHook from 'hook';
import './style.less';

const { useGetPublicArticleForView } = customHook;
const BlogCore = () => {
  const { data, getPublicArticleForView } = useGetPublicArticleForView();
  const [searchParams] = useSearchParams();
  const nameAscii = searchParams.get('id');
  useEffect(() => {
    if (nameAscii) {
      getPublicArticleForView({
        nameAscii
      });
    } else {
      getPublicArticleForView({
        nameAscii: null
      });
    }
  }, [nameAscii]);

  const renderBlog = useMemo(() => {
    if (data) {
      const { thumbnailUrl, name, summary, title, detail } = data;
      return (
        <Row className="blogCore">
          <Col span={12} className="group">
            <div className="group-img">
              <img src={thumbnailUrl} loading='lazy' />
            </div>
            <div className="group-item">
              <h1>{name}</h1>
              <p>{summary}</p>
            </div>
            <div className="group-item">
              <h1>{title}</h1>
              <p>{detail}</p>
            </div>
          </Col>
        </Row>
      );
    }
  }, [data]);

  return <React.Fragment>{renderBlog}</React.Fragment>;
};

export default BlogCore;
