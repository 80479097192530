import React, { useState } from 'react'
import services from 'services'
import { generateUUID } from 'three/src/math/MathUtils';

const useImgAddon = () => {
    const [loading, setLoading] = useState(false);
    const [imgAddonList, setImgAddonList] = useState([])

    const getImgAddon = async () => {
        try {
            setLoading(true);
            const response = await services.GetImgAddon();
            if (response?.success) {
                const { result: { items } } = response
                setImgAddonList(items)
            }
            return response
        } catch (error) {
            throw new Error(error)
        } finally {
            setLoading(false);
        }
    }

    const addImgAddon = async (file: any) => {
        try {
            setLoading(true);
            const uniqueFileName = `${new Date().valueOf()}_${file.name.replace(/\s/g, '_')}`
            const response = await services.GetS3PreSignedUrl({ fileNames: uniqueFileName })
            if (response?.success) {
                const { result: [fileUrl] } = response
                const fullUrl = new URL(fileUrl)
                const url = fullUrl.origin.concat(fullUrl.pathname)
                const params = Object.fromEntries(fullUrl.searchParams);
                const binaryFile = file
                const resPutFile = await services.PutS3File({ url, params, body: binaryFile })
                if (resPutFile.status === 200) {
                    const res = await services.PostImgAddon({ url })
                    if (res.success) {
                        return {
                            url,
                            success: true
                        }
                    }

                }
            }
            // const response = await services.PostImgAddon(data);
            return response
        } catch (error) {
            throw new Error(error);
        } finally {
            setLoading(false);
        }
    }

    const delImgAddon = async (data: any) => {
        try {
            setLoading(true);
            const response = await services.DelImgAddon(data);
            return response
        } catch (error) {
            throw new Error(error)
            // console.log('error', error);
        } finally {
            setLoading(false);
        }
    }

    return {
        loading,
        imgAddonList,
        setImgAddonList,
        getImgAddon,
        addImgAddon,
        delImgAddon
    }
}

export default useImgAddon