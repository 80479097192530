import React, { useState } from 'react';
import services from 'services';

const useGetPostForView = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null)

    const getPostForView = async (param) => {
        try {
            setLoading(true);
            const response = await services.GetPostForView(param);
            if (response?.success) {
                const { result: { post } } = response
                setData(post)
            }
            return response
        } catch (error) {
            throw new Error(error)
        } finally {
            setLoading(false);
        }
    };
    return {
        loading,
        getPostForView,
        postData: data
    };
};

export default useGetPostForView;
