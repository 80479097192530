import React, { useRef, useState, useEffect } from 'react'
import { Select } from 'antd';
import './style.less'

const { Option } = Select
const SortCore = ({ data }) => {
    const { handleSort, parameters: { sorting } } = data
    const renderSort = React.useMemo(() => {
        return (
            <div className='sortCore'>
                <div className='title'>
                    <span>Sort By</span>
                </div>
                <Select style={{ width: '100%' }} value={sorting} onChange={handleSort} >
                    <Option value='name'>Name</Option>
                    <Option value='creationTime desc'>Date</Option>
                </Select>
            </div>
        )
    }, [sorting])

    return (
        <React.Fragment>
            {renderSort}
        </React.Fragment>
    )
}

export default SortCore