import React, {useLayoutEffect, useState} from 'react';
import { Row, Col, Button, Image, Modal } from 'antd';
import { BsThreeDotsVertical, BsThreeDots } from 'react-icons/bs';
import { AiOutlineEdit, AiOutlineSetting, AiOutlineDelete } from 'react-icons/ai';
import { MdOutline360 } from 'react-icons/md';
import { demo } from 'assets';
import { EXPLORE, MY_PROFILE, PAGE_ROUTE } from '@routes/constants';
import './style.less';
import { useNavigate } from 'react-router-dom';
import { ENVIRONMENT } from '@services/environment'
import axios from 'axios';
import cookie from "js-cookie";

const CardPictureCore = ({ data }) => {
  const { result, key, handleEditTour, handleEditPost, handleDeleteTour, handleDeletePost } = data;
  const navigate = useNavigate();

  const ThumbnailImg = ({ data }) => {
    const { post, tour } = data;
    return <img src={post ? post.thumbnailUrl : tour.posts?.[0] ? tour.posts?.[0]?.thumbnailUrl : demo  } loading='lazy' />;
  };
  const TilteImg = ({ data }) => {
    const { post, tour } = data;
    if (post) {
      return <span className="tour-name">{post.name}</span>;
    }
    else if (tour) {
      const { name, posts: { length }, description } = tour;
      return (
        <React.Fragment>
          <span className="tour-name">{name}</span>
          {key === EXPLORE.tours && <span className="tour-description">{description}</span>}
          <span className="tour-posts">{`${length} Posts`}</span>
        </React.Fragment>
      );
    }
  };
  const PrivacyImg = ({ data }) => {

    const { rest, author, profilePicture, index } = data;
    const item = rest.tour || rest.post;
    const { privacy } = item;
    const [avatar, setAvatar] = useState('')

  //   const customHeaders = {
  //     'Access-Control-Allow-Origin': '*',
  //     'Cache-Control': 'no-cache'
  // }

  // useLayoutEffect(() => {
  //     axios.get(`${ENVIRONMENT.AvatarURL}${profilePicture}`, {
  //       headers: customHeaders
  //     }).then(res => 
  //       setAvatar(`${ENVIRONMENT.AvatarURL}${profilePicture}`)
  //     )
  //     .catch(err => {
  //       setAvatar(demo)
  //     })
  // }, [profilePicture])

    // const avatar = profilePicture
    //   ? `${ENVIRONMENT.AvatarURL}${profilePicture}`
    //   : demo;


    if (key !== EXPLORE.tours) {
      return (
        <Col
          className={`${privacy ? 'public' : 'private'} detail`}
          span={key === MY_PROFILE.tours ? 24 : 12}>
          {key === MY_PROFILE.tours || key === MY_PROFILE.photos ? (
            <div className="privacy">
              <span>{privacy ? 'public' : 'private'}</span>
            </div>
          ) : (
            <React.Fragment>
              <img src={demo} loading='lazy'/>
              <span>{author || `User Demo ${index}`}</span>
            </React.Fragment>
          )}
          {key === MY_PROFILE.tours && (
            <div type="text" className="tour-menu_btn">
              <BsThreeDotsVertical />
              <div className={`tour-menu`}>
                <Button type="text" onClick={() => handleEditTour(rest)}>
                  <span>
                    <AiOutlineSetting />
                  </span>
                  <span>Edit Tour</span>
                </Button>
                <Button type="text" onClick={() => handleEditPost({ rest, key })}>
                  <span>
                    <AiOutlineEdit />
                  </span>
                  <span>Edit Post</span>
                </Button>
                <Button type="text" onClick={() => handleDeleteTour({ rest, key })}>
                  <span>
                    <AiOutlineDelete />
                  </span>
                  <span>Delete</span>
                </Button>
              </div>
            </div>
          )}
        </Col>
      );
    }
  };
  const navigateViewItem = ({ data, key }) => {
    const { post } = data;
    navigate(`/${PAGE_ROUTE.POST}/${post.id}`);
  };
  return (
    <Row className={`${key}_list cardCore`}>
      {result &&
        result.items?.map(({ author, profilePicture, ...rest }, index) => {
          return (
            <Col key={index} className="group-item" span={4}>
              <Row>
                <Col
                  onClick={() => navigateViewItem({ data: rest, key })}
                  className="card"
                  span={24}>
                  <ThumbnailImg data={rest} />
                  <div className="background">
                    <TilteImg data={rest} />
                  </div>
                  {key === MY_PROFILE.photos || key === EXPLORE.photos ? (
                    <div className="icon">
                      <span>360</span>
                      <MdOutline360 />
                    </div>
                  ) : null}
                </Col>
                <PrivacyImg data={{ rest, author, profilePicture, index }} />
                {key === MY_PROFILE.photos && (
                  <Col span={12} className="post-menu_btn">
                    <BsThreeDots />
                    <div className={`post-menu`}>
                      <Button type="text" onClick={() => handleEditPost({ rest, key })}>
                        <span>
                          <AiOutlineEdit />
                        </span>
                        <span>Edit</span>
                      </Button>
                      <Button type="text" onClick={() => handleDeletePost({ rest })}>
                        <span>
                          <AiOutlineDelete />
                        </span>
                        <span>Delete</span>
                      </Button>
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
          );
        })}
    </Row>
  );
};

export default CardPictureCore;
