import React, { useState } from 'react'
import services from 'services'

const useCreateOrEditTour = () => {
  const [loading, setLoading] = useState(false);

  const createOrEditTour = async (data: { configs: any; posts: any[]; }) => {
    try {
      setLoading(true);
      const handledData = {
        ...data,
        configs: JSON.stringify(data.configs),
        posts: data.posts?.map(post => {
          return {
            ...post,
            configs: JSON.stringify(post.configs),
            addons: post.addons?.map(addon => {
              return {
                ...addon,
                configs: JSON.stringify(addon.configs),
              }
            })
          }
        })
      }
      const response = await services.CreateOrEditTour(handledData)
      return response
    } catch (error) {
      throw new Error(error)
    } finally {
      setLoading(false);
    }
  }
  return {
    loading,
    createOrEditTour
  }
}

export default useCreateOrEditTour