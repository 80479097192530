import React, { useState } from 'react'
import services from 'services'

const useComment = () => {
    const [loading, setLoading] = useState(false);
    const [commentList, setCommentList] = useState([])
    const [totalCount, setTotalCount] = useState(null)

    const getComment = async (data: any) => {
        try {
            setLoading(true);
            const response = await services.GetComment({ ...data, entity: 'Post' });
            if (response?.success) {
                const { result: { totalCount, items } } = response
                setCommentList(items)
                setTotalCount(totalCount)
            }
            return response
        } catch (error) {
            throw new Error(error)
            // console.log('error', error);
        } finally {
            setLoading(false);
        }
    }

    const addComment = async (data: any) => {
        try {
            setLoading(true);
            const response = await services.AddComment({ ...data, entity: 'Post' });
            return response
        } catch (error) {
            throw new Error(error)
            // console.log('error', error);
        } finally {
            setLoading(false);
        }
    }

    const editComment = async (data: any) => {
        try {
            setLoading(true);
            // const response = await services.AddComment(data);
            // return response
        } catch (error) {
            throw new Error(error)
            // console.log('error', error);
        } finally {
            setLoading(false);
        }
    }

    const delComment = async (data: any) => {
        try {
            setLoading(true);
            // const response = await services.DelComment(data);
            // return response
        } catch (error) {
            throw new Error(error)
            // console.log('error', error);
        } finally {
            setLoading(false);
        }
    }

    return {
        loading,
        commentList,
        totalCount,
        getComment,
        addComment,
        editComment,
        delComment
    }
}

export default useComment