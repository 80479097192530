import React from 'react'
import { Col, Row } from 'antd'
import FooterLogo from "./FooterLogo.svg";
import './style.less'

const footerData = [
    {
        title: 'Company',
        items: [
            'About Us',
            'Careers',
            'Blog',
            'Pricing'
        ]
    },
    {
        title: 'Product',
        items: [
            'Invoicing Platform',
            'Accounting Plateform',
            'Create Proposal',
            'Contracts'
        ]
    },
    {
        title: 'Resources',
        items: [
            'Proposal Template',
            'Invoice Template',
            'Tuturoial',
            'How to write a contract'
        ]
    }
]

const FooterCore = () => {
    const renderFooter = React.useMemo(() => {
        return (
            <Row className='footerCore'>
                <Col span={22} className="wrapper">
                    <Row className='content'>
                        <Col span={24} className='introduce' xs={{ span: 20 }} lg={{ span: 11 }}>
                            <img src={FooterLogo} loading='lazy' />
                            <p>Yet bed any for travelling assistance indulgence unpleasing. Not thoughts all exercise blessing. Indulgence way everything joy alteration boisterous the attachment.</p>
                        </Col>
                        <Col span={16} className='group' xs={{ span: 20 }} lg={{ span: 12 }}>
                            {footerData?.map((data, index) => {
                                return (
                                    <div key={index} className='group-item'>
                                        <h1>{data.title}</h1>
                                        {data.items.map((item, index) => {
                                            return (
                                                <a key={index}>{item}</a>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                </Col>
                <Col span={24} className='footer'>
                    <Row>
                        <Col span={12}>
                            <p>2022 AR Shakir. All rights reserved. -- Privacy Policy - Terms of Services</p>
                        </Col>
                        <Col span={12}>
                            <p>Supported by Microsoft Startup</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }, [])

    return (
        <React.Fragment>
            {renderFooter}
        </React.Fragment>
    )
}

export default FooterCore