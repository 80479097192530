import React, { useEffect, useState } from 'react'
import { Tabs, Radio, Row, Col, Button, Image, Modal } from 'antd';
import { BsThreeDotsVertical, BsThreeDots } from 'react-icons/bs'
import { AiOutlineEdit, AiOutlineSetting, AiOutlineDelete } from 'react-icons/ai'
import { MdOutline360 } from 'react-icons/md'
import { demo } from 'assets';
import { EXPLORE, MY_PROFILE } from '@routes/constants';
import './style.less'
import { createSearchParams, useNavigate } from 'react-router-dom';

const CardTourCore = ({ data }) => {
    const { result, key, handleEditTour, handleEditPost, handleDeleteTour, handleDeletePost } = data
    const navigate = useNavigate()
    const ThumbnailImg = ({ data }) => {
        if (data.post) {
            return (
                <img alt={demo} src={data.post.thumbnailUrl || demo} loading='lazy' />
            )
        } else if (data.tour) {
            const { tour } = data
            if (tour.posts && tour.posts[0]) {
                return (
                    <img alt={demo} src={tour.posts[0].thumbnailUrl || demo} loading='lazy' />
                )
            } else {
                return (
                    <img alt={demo} src={demo} loading='lazy' />
                )
            }
        }
    }
    const TilteImg = ({ data }) => {
        if (data.post) {
            const { post: { name } } = data
            return (
                <span className='tour-name'>{name}</span>
            )
        } else if (data.tour) {
            const { tour: { name, posts, description } } = data
            return (
                <React.Fragment>
                    <span className='tour-name'>{name}</span>
                    {key === EXPLORE.tours && <span className='tour-description'>{description}</span>}
                    <span className='tour-posts'>{`${posts.length} Posts`}</span>
                </React.Fragment>
            )
        }
    }
    const PrivacyImg = ({ data }) => {
        const { rest, author, profilePicture, index } = data
        const item = rest.tour || rest.post
        const avatar = profilePicture ? `data:image/jpeg;base64,${profilePicture}` : demo
        if (key !== EXPLORE.tours) {
            return (
                <Col className={`${item.privacy ? 'public' : 'private'} detail`} span={key === MY_PROFILE.tours ? 24 : 12}>
                    {key === MY_PROFILE.tours || key === MY_PROFILE.photos ? (
                        <div className='privacy'>
                            <span >{item.privacy ? 'public' : 'private'}</span>
                        </div>
                    ) : (
                        <React.Fragment>
                            <img src={avatar} loading='lazy' />
                            <span>{author || `User Demo ${index}`}</span>
                        </React.Fragment>
                    )}
                    {key === MY_PROFILE.tours &&
                        <div type='text' className='tour-menu_btn' >
                            <BsThreeDotsVertical />
                            <div className={`tour-menu`}>
                                <Button type='text' onClick={() => handleEditTour(rest)}>
                                    <span><AiOutlineSetting /></span>
                                    <span>Edit Tour</span>
                                </Button>
                                <Button type='text' onClick={() => handleEditPost({ rest, key })}>
                                    <span><AiOutlineEdit /></span>
                                    <span>Edit Post</span>
                                </Button>
                                <Button type='text' onClick={() => handleDeleteTour({ rest, key })}>
                                    <span><AiOutlineDelete /></span>
                                    <span>Delete</span>
                                </Button>
                            </div>
                        </div>
                    }
                </Col>
            )
        }
    }
    const navigateViewItem = ({ data, key }) => {
        const { tour } = data
        navigate(`/tour/${tour.id}/post/${tour.posts[0].id}`)
    }
    return (
        <Row className={`${key}_list cardCore`}>
            {result &&
                result.items?.map(({ author, profilePicture, ...rest }, index) => {
                    return (
                        <Col key={index} className='group-item' span={4}>
                            <Row>
                                <Col onClick={() => navigateViewItem({ data: rest, key })} className='card' span={24}>
                                    <ThumbnailImg data={rest} />
                                    <div className='background'>
                                        <TilteImg data={rest} />
                                    </div>
                                    {key === MY_PROFILE.photos || key === EXPLORE.photos ? (
                                        <div className='icon'>
                                            <span>360</span>
                                            <MdOutline360 />
                                        </div>
                                    ) : null}
                                </Col>
                                <PrivacyImg data={{ rest, author, profilePicture, index }} />
                                {key === MY_PROFILE.photos && (
                                    <Col span={12} className='post-menu_btn'>
                                        <BsThreeDots />
                                        <div className={`post-menu`}>
                                            <Button type='text' onClick={() => handleEditPost({ rest, key })}>
                                                <span><AiOutlineEdit /></span>
                                                <span>Edit</span>
                                            </Button>
                                            <Button type='text' onClick={() => handleDeletePost({ rest })}>
                                                <span><AiOutlineDelete /></span>
                                                <span>Delete</span>
                                            </Button>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    )
                })}
        </Row>
    )
}

export default CardTourCore
