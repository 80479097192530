export const API = {
  AUTHENTICATE: 'api/TokenAuth/Authenticate',
  GET_INFO_USER: 'api/services/app/Session/GetCurrentLoginInformations',
  GET_PUBLIC_POSTS: 'api/services/app/PublicPosts/GetAll',
  GET_PUBLIC_TOURS: 'api/services/app/PublicTours/GetAll',
  GET_PUBLIC_ARTICLE: 'api/services/app/PublicArticles/GetAll',
  POST_CREATE_POST: '​api​/services​/app​/GeneralQuestions​/GetAll',
  REGISTER: 'api/services/app/Account/KuulaRegister',
  GET_S3_PRESIGNED_URL: '/api/services/app/Posts/GetS3PreSignedUrl',
  // CREATE_OR_EDIT_TOUR: '/api/services/app/Tours/CreateOrEdit',
  CREATE_OR_EDIT_TOUR: '/api/services/app/Tours/UpdateTourAndReturnListId',
  CREATE_TOUR_AND_RETURN_ID: '/api/services/app/Tours/CreateAndReturnListId',
  GET_TOUR_FOR_EDIT_PUBLIC: '/api/services/app/PublicTours/GetTourForEdit',
  GET_POST_FOR_EDIT_PUBLIC: '/api/services/app/PublicPosts/GetPostByPostAndTourId',
  GET_POST_FOR_EDIT_BY_POST_ID: '/api/services/app/PublicPosts/GetPostForEdit',
  GET_MY_POSTS: 'api/services/app/Posts/GetAll',
  GET_MY_TOURS: 'api/services/app/Tours/GetAll',
  GET_CURRENT_USER_PROFILE: 'api/services/app/Profile/GetCurrentUserProfileForEdit',
  GET_CURRENT_USER_PROFILE_PIC: 'api/services/app/Profile/GetProfilePicture',
  RESET_PASSWORD: 'api/services/app/Account/SendPasswordResetCode',
  UPDATE_USER_PROFILE: 'api/services/app/Profile/UpdateCurrentUserProfile',
  // UPLOAD_AVATAR: 'api/services/app/Profile/UploadProfilePicture?',
  UPDATE_USER_PIC: 'api/services/app/Profile/UpdateProfilePicture',
  UPDATE_USER_PASS: 'api/services/app/Profile/ChangePassword',
  DELETE_USER_TOUR: 'api/services/app/Tours/Delete',
  CREATE_OR_EDIT_POST: '/api/services/app/Posts/CreateOrEdit',
  DELETE_USER_POST: '/api/services/app/Posts/Delete',
  GET_PUBLIC_ARTICLE_FOR_VIEWS: '/api/services/app/PublicArticles/GetArticleForView',
  GET_LIST_TOUR_FOR_EDIT: '/api/services/app/PostTours/GetAllTourForLookupTable',
  SET_NEW_PASSWORD: '/api/services/app/Account/ResetPassword',
  GET_COMMENT: '/api/services/app/UserCommentEntities/GetAllCommentForThisEntity',
  POST_COMMENT: '/api/services/app/UserCommentEntities/NewCommentEntity',
  EDIT_COMMENT: '/api/services/app/UserCommentEntities/EditCommentEntity',
  DEL_COMMENT: '/api/services/app/UserCommentEntities/DeleteCommentEntity',
  CREATE_MULTI_POST: '/api/services/app/Posts/UploadMultiPost',
  GET_POST_VIEW: '/api/services/app/PublicPosts/GetPostForView',
  GET_S3_AVATAR: '/api/services/app/Profile/GetS3PreSignedUrlToUpdateAvatarProfile',
  GET_LIKE: '/api/services/app/UserLikeEntities/GetTotalLikesForEntity',
  POST_LIKE_ENTI: '/api/services/app/UserLikeEntities/LikeAnEntity',
  POST_UNLIKE_ENTI: '/api/services/app/UserLikeEntities/Unlike',
  GET_IS_LIKE_ENTI: '/api/services/app/UserLikeEntities/IsUserLikedThisEntity',
  GET_IMG_ADDON: '/api/services/app/Addons/GetAllImageAddon',
  POST_IMG_ADDON: '/api/services/app/Addons/CreateImgAddon',
  DEL_IMG_ADDON: '/api/services/app/Addons/DeleteImgAddon',
  CREATE_POST_AND_RETURN_ID: '/api/services/app/Posts/CreateAndReturnId'
};
