import React, { useState } from 'react'
import services from 'services';
import axios from 'axios';
import { ENVIRONMENT } from 'services/environment';
import { demo } from 'assets';

const customHeaders = {
    'Access-Control-Allow-Origin': '*',
    'Cache-Control': 'no-cache'
}

const useGetProfileUser = () => {
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState()

    const getUser = async () => {
        try {
            setLoading(true);
            const response = await services.GetCurrentUserProfile()
            if (response?.success) {
                const { result: items } = response
                axios.get(`${ENVIRONMENT.AvatarURL}${items.avatarUrl}`, { headers: customHeaders }).then(res => {
                    setUserInfo({
                        ...items,
                        avatarUrl: `${ENVIRONMENT.AvatarURL}${items.avatarUrl}`
                    })
                }).catch(err => {
                    setUserInfo({
                        ...items,
                        avatarUrl: demo
                    })
                })
            }
            return response
        } catch (error) {
            throw new Error(error)
        } finally {
            setLoading(false);
        }
    }

    // const getUserAvatar = async () => {
    //     try {
    //         setLoading(true);
    //         const { result: picture } = await services.GetCurrentUserPic()
    //         setUserAvatar(picture.profilePicture)
    //     } catch (error) {
    //         console.log('error', error)
    //     } finally {
    //         setLoading(false);
    //     }
    // }
    return {
        loading,
        userInfo,
        // userAvatar,
        getUser,
        // getUserAvatar
    }
}

export default useGetProfileUser