import cookie from "js-cookie"
import { Navigate } from "react-router-dom"

export const setToken = ({token}) => {
    try {
        cookie.set('token', token, {expires: 356})
    }
    catch (error) {
    }
}
export const setTokenUser = (user) => {
    cookie.set('user', JSON.stringify(user))
}
export const getTokenUser = () => {
    let user = cookie.get('user');
    return user != null ? JSON.parse(user) : null;
  };
  export const logout = (redirect = '/login') => {
    cookie.remove('token');
    <Navigate to={redirect} replace={true} />
    // to support logging out from all windows
    // window.localStorage.setItem("logout", Date.now());
    // Router.push(`/login?r=${encodeURIComponent(redirect)}`);
  };