import { Result } from 'antd';
import React, { useState } from 'react'
import services from 'services'

const useUpdateUserPic = () => {
    const [loading, setLoading] = useState(false);

    const uploadUserPic = async (form: any) => {
        try {
            setLoading(true);
            // const response = await services.SendUploadPicture(form);
            // if (response.success) {
            //     const { result: { fileToken } } = response
            //     return await services.UpdateUserPicture({ fileToken: fileToken })
            // }
            // return response
            const response = await services.GetS3Avatar()
            if (response?.success) {
                // const { result } = response
                const fullUrl = new URL(response.result)
                const url = fullUrl.origin.concat(fullUrl.pathname)
                const params = Object.fromEntries(fullUrl.searchParams);
                const binaryFile = form
                return await services.PutS3Avatar({ url, params, body: binaryFile })
            }
        } catch (error) {
            throw new Error(error)
        } finally {
            setLoading(false);
        }
    }

    return {
        loading,
        uploadUserPic,
    }
}

export default useUpdateUserPic