import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Row, Spin } from 'antd';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { AiOutlineDoubleRight } from 'react-icons/ai';
import customHook from 'hook';
import LoadMoreBtn from '../LoadMoreBtn';
import { PAGE_ROUTE } from '@routes/constants';
import './style.less';

const defaultState = {
    articleTypeFilter: 4,
    maxResultCount: 3
};

const { useGetArticle } = customHook;
const NewsCore = () => {
    const navigate = useNavigate();
    const { data: news, getArticles, totalCount, loading } = useGetArticle();
    const [parameters, setParameters] = useState(defaultState);

    useEffect(() => {
        getArticles(parameters);
    }, [parameters]);

    const handleLoad = () => {
        setParameters((prev) => ({
            ...prev,
            maxResultCount: prev.maxResultCount + 3
        }));
    };


    const handleClick = (index) => {
        navigate(`/${PAGE_ROUTE.BLOG_DETAIL}?${createSearchParams({ id: index })}`);
    };

    const renderNews = useMemo(() => {
        if (news) {
            return (
                <Row className='newsCore'>
                    <Col span={24} className='title'>
                        <h1>What's News</h1>
                    </Col>
                    <Col span={20} className='group'>
                        {news?.map(({ article }, index) => {
                            return (
                                <Col key={index} className='group-item' span={7}>
                                    <img loading='lazy' onClick={() => handleClick(article.nameAscii)} src={article.thumbnailUrl} />
                                    <h1 onClick={() => handleClick(article.nameAscii)}>{article.name}</h1>
                                    <p>{article.summary}</p>
                                    <Button onClick={() => handleClick(article.nameAscii)} type='link' icon={<AiOutlineDoubleRight />} >Read more</Button>
                                </Col>
                            )
                        })}
                    </Col>
                    {news &&
                        <LoadMoreBtn
                            items={news}
                            totalCount={totalCount}
                            onClick={handleLoad}
                            count={parameters.maxResultCount}
                        />
                    }
                </Row>
            )
        }
    }, [news])

    return (
        <>
            {/* <Spin tip='Loading...' spinning={loading}> */}
            {renderNews}
            {/* </Spin> */}
        </>
    )
}

export default NewsCore;
