import React, { useState } from 'react'
import services from 'services'

const useDeleteUserTour = () => {
    const [loading, setLoading] = useState(false)
    
    const deleteUserTour = async (param: any) => {
        try {
            setLoading(true)
            const response = await services.DeleteUserTour(param)
            return response
        } catch (error) {
            throw new Error(error)
        } finally {
            setLoading(false)
        }
    }

    return {
        loading,
        deleteUserTour
    }
}

export default useDeleteUserTour