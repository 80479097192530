import React from 'react'
import { Input } from 'antd';
import './style.less'

const { Search } = Input;
const SearchCore = ({ data }) => {
    const { handleSearch } = data
    const renderSearch = React.useMemo(() => {
        return (
            <Search placeholder='Search...' onSearch={handleSearch} className='searchCore' />
        )
    }, [])

    return (
        <React.Fragment>
            {renderSearch}
        </React.Fragment>
    )
}

export default SearchCore