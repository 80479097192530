import React, { useState } from 'react'
import services from 'services'

const useCreateTourAndReturnId = () => {
  const [loading, setLoading] = useState(false);

  const createTourAndReturnId = async (data) => {
    try {
      setLoading(true);
      const handledData = {
        ...data,
        configs: JSON.stringify(data.configs),
        posts: data.posts?.map(post => {
          return {
            ...post,
            configs: JSON.stringify(post.configs),
          }
        })
      }
      const response = await services.CreateTourAndReturnId(handledData)
      return response.result;
    } catch (error) {
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  }
  return {
    loading,
    createTourAndReturnId
  }
}

export default useCreateTourAndReturnId