import React, { useState } from 'react';
import services from 'services';

const useGetTourForEdit = () => {
  const [loading, setLoading] = useState(false);
  const [tourForEdit, setTourForEdit] = useState()

  const getTourForEdit = async (param) => {
    try {
      setLoading(true);
      const response = await services.GetTourForEdit(param);
      if (response?.success) {
        if (!response.result) {
          return {};
        }
        const processedTour = {
          ...response.result.tour,
          configs: JSON.parse(response.result.tour.configs),
          posts: response.result?.tour.posts.map(post => {
            return {
              ...post,
              configs: JSON.parse(post.configs),
              addons: post.addons.map(addon => {
                return {
                  ...addon,
                  configs: JSON.parse(addon.configs)
                }
              })
            }
          }),
          author: response.result.author,
          profilePicture: response.result.profilePicture,
        }
        setTourForEdit(processedTour)
        return processedTour
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };
  return {
    tourForEdit,
    loading,
    getTourForEdit
  };
};

export default useGetTourForEdit;
