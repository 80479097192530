import './App.css';
import "antd/dist/antd.min.css";
import './assets/styles/main.less'
import AppRoutes from './routes';

function App() {
  return (
    <AppRoutes />
  );
}

export default App;
