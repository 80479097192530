import React, { useState } from 'react'
import services from 'services'

const useUpdateUserProfile = () => {
    const [loading, setLoading] = useState(false);

    const updateUserProfile = async (form: any) => {
        try {
            setLoading(true);
            const response = await services.UpdateUserProfile(form)
            return response
        } catch (error) {
            throw new Error(error)
        } finally {
            setLoading(false);
        }
    }
    return {
        loading,
        updateUserProfile
    }
}

export default useUpdateUserProfile