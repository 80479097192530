import BlogCore from "./Blog/BlogCore";
import CardPictureCore from "./CardPicture/CardPictureCore";
import HeaderImgCore from "./HeaderImg/HeaderImgCore";
import FooterCore from "./Footer/FooterCore";
import NewsCore from "./News/NewsCore";
import ScrollToTop from "./ScrollTop/ScrollToTop";
import SearchCore from "./Search/SearchCore";
import SortCore from "./Sort/SortCore";
import UsersChatCore from "./UsersChat/UsersChatCore";
import LoadMoreBtn from "./LoadMoreBtn";
import CardTourCore from "./CardPicture/CardTourCore";

export default {
    BlogCore,
    CardPictureCore,
    HeaderImgCore,
    FooterCore,
    NewsCore,
    ScrollToTop,
    SearchCore,
    SortCore,
    UsersChatCore,
    LoadMoreBtn,
    CardTourCore
}