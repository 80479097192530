import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import { message } from "antd";
import { MY_PROFILE, SETTING_PROFILE } from "@routes/constants";
import { ENVIRONMENT } from '@services/environment';
import { useAuthContext } from "./AuthContext";
import { demo } from "assets";
import useCustomHook from 'hook'

const customHeaders = {
    'Access-Control-Allow-Origin': '*',
    'Cache-Control': 'no-cache'
}

const {
    useGetMyPosts,
    useGetMyTours,
    useUpdateUserProfile,
    useUpdateUserPic,
    useGetProfileUser,
    useUpdatePassword
} = useCustomHook

const PrivateDataContext = createContext()

export const usePrivateDataContext = () => useContext(PrivateDataContext)

export const PrivateDataProvider = ({ children }) => {
    const [pathName, setPathName] = useState('')
    const { data: myPosts, getPosts, totalCount: totalPosts, loading: loadPosts } = useGetMyPosts()
    const { data: myTours, getTours, totalCount: totalTours, loading: loadTours } = useGetMyTours()
    const { userInfo, getUser } = useGetProfileUser()
    const { updateUserProfile } = useUpdateUserProfile()
    const { uploadUserPic } = useUpdateUserPic()
    const { updateUserPass } = useUpdatePassword()
    const { logout, isAuthenticated } = useAuthContext()
    let { current: result } = useRef()

    // Handle Photos and Tours
    const handleData = async ({ param, ...rest }) => {
        setPathName(param)
        if (param === MY_PROFILE.photos) {
            return await getPosts(rest)
        } else if (param === MY_PROFILE.tours) {
            return await getTours(rest)
        }
    }

    // Handle Update Profile And Pass
    const handleUpdateProfile = ({ param, ...rest }) => {
        const { data } = rest
        if (param === SETTING_PROFILE.edit) {
            const { name, surname, emailAddress } = data
            const response = updateUserProfile({
                ...userInfo,
                name,
                surname,
                emailAddress
            }).then(res => {
                if (res?.success) {
                    message.success("Success")
                } else {
                    message.error(res.data.error.message)
                }
            })
            return response
        } else if (param === SETTING_PROFILE.changePass) {
            const { oldPassword, newPassword } = data
            const response = updateUserPass({
                currentPassword: oldPassword,
                newPassword
            }).then(res => {
                if (res?.success) {
                    message.success("Success")
                    setTimeout(() => {
                        logout()
                    }, 1000);
                } else {
                    message.error(res.data.error.message)
                }
            })
            return response
        }
    }

    // Handle Update avatar
    const handleUpdateUserPic = async (data) => {
        // const formData = new FormData()
        // formData.append('profilePictureFile', data)
        // const params = {
        //     fileName: data.name,
        //     fileType: data.type,
        //     fileToken: data.uid,
        // }
        // const config = { 'content-type': 'multipart/form-data' }
        // return await uploadUserPic({ params, formData, config })
        return await uploadUserPic(data)
    }

    const { result: data } = useMemo(() => {
        switch (pathName) {
            case MY_PROFILE.photos:
                result = {
                    name: MY_PROFILE.photos,
                    items: myPosts,
                    count: totalPosts,
                    loading: loadPosts
                };
                break;
            case MY_PROFILE.tours:
                result = {
                    name: MY_PROFILE.tours,
                    items: myTours,
                    count: totalTours,
                    loading: loadTours
                };
                break;
            default:
                break;
        }
        return {
            result
        }
    }, [pathName, myPosts, myTours, loadPosts, loadTours])

    useEffect(() => {
        if (isAuthenticated) {
            getUser()
        }
    }, [isAuthenticated])

    const privateDataContextValue = useMemo(
        () => ({
            data,
            getPosts,
            getTours,
            setPathName,
            handleData,
            userInfo,
            getUser,
            handleUpdateProfile,
            handleUpdateUserPic,
            updateUserPass,
            isAuthenticated
        }),
        [data, userInfo, pathName, isAuthenticated, loadPosts, loadTours],
    );

    return (
        <PrivateDataContext.Provider value={privateDataContextValue}>
            {children}
        </PrivateDataContext.Provider>
    );
}