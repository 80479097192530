import React from 'react'
import { Col, Button } from 'antd'
import './style.less'

const LoadMoreBtn = ({ items, totalCount, onClick, count }) => {
    // const isShow = items.length <= count && count < totalCount ? true : false
    // const isShow = items.length <= count && count < totalCount
    return (
        items.length <= count && count < totalCount &&
        (
            <Col span={24} className='loadMore_btn'>
                <Button type='primary' onClick={onClick}>Load More</Button>
            </Col>
        )
    )
}

export default LoadMoreBtn