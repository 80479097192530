import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import customCore from '@components/Core'
import { routePath } from './routePath';
import { AuthProvider } from 'context/AuthContext';
import { PublicDataProvider } from 'context/PublicDataContext';
import { PrivateDataProvider } from 'context/PrivateDataContext';

const MainLayOut = lazy(() => import('@pages/MainLayOut'))
const Home = lazy(() => import('@components/Home/HomePage'))

const { ScrollToTop } = customCore
const AppRoutes = () => (
    <BrowserRouter>
        <PublicDataProvider>
            <AuthProvider>
                <PrivateDataProvider>
                    <ScrollToTop />
                    <Suspense fallback={null}>
                        <MainLayOut />
                        {/* <Routes>
                            <Route index element={<Home />} />
                            {routePath?.map(({ path, ...rest }, index) => (
                                <Route path={path} key={index} {...rest} />
                            ))}
                        </Routes> */}
                    </Suspense>
                </PrivateDataProvider>
            </AuthProvider>
        </PublicDataProvider>
    </BrowserRouter>
)

export default AppRoutes

