import { httpService } from '../services/service';
import { API } from './endPoint';

const Authenticate = async (data) => {
  const response = await httpService.post(API.AUTHENTICATE, data);
  if (!response) {
    throw new Error(response);
  }
  return response;
};
const GetPublicArticle = async (param) => {
  const response = await httpService.get(API.GET_PUBLIC_ARTICLE, param);
  if (!response) {
    return [];
  }
  return response;
};
const GetPublicPosts = async (param) => {
  const response = await httpService.get(API.GET_PUBLIC_POSTS, param);
  if (!response) {
    return [];
  }
  return response;
};
const GetPublicTours = async (param) => {
  const response = await httpService.get(API.GET_PUBLIC_TOURS, param);
  if (!response) {
    return [];
  }
  return response;
};
const Register = async (data) => {
  const response = await httpService.post(API.REGISTER, data);
  if (!response) {
    throw new Error(response);
  }
  return response;
};
const GetInforUser = async () => {
  const response = await httpService.get(API.GET_INFO_USER);
  if (!response) {
    return [];
  }
  return response;
};
const GetS3PreSignedUrl = async (params) => {
  const response = await httpService.get(API.GET_S3_PRESIGNED_URL, params);
  if (!response) {
    return [];
  }
  return response;
};
const GetMyPosts = async (param) => {
  const response = await httpService.get(API.GET_MY_POSTS, param);
  if (!response) {
    return [];
  }
  return response;
};
const GetMyTours = async (param) => {
  const response = await httpService.get(API.GET_MY_TOURS, param);
  if (!response) {
    return [];
  }
  return response;
};
const GetCurrentUserProfile = async () => {
  const response = await httpService.get(API.GET_CURRENT_USER_PROFILE);
  if (!response) {
    return [];
  }
  return response;
};
const PutS3File = async ({ url, params, body }) => {
  const response = await httpService.putUrl({ url, params, body });
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const CreateOrEditTour = async (data) => {
  const response = await httpService.put(API.CREATE_OR_EDIT_TOUR, data, {
    'content-type': 'application/json'
  });
  if (!response) {
    throw new Error(response);
  }
  response.success || message.error(response.data.error.message);
  return response;
};

const CreateTourAndReturnId = async (data) => {
  const response = await httpService.post(API.CREATE_TOUR_AND_RETURN_ID, data, {
    'content-type': 'application/json'
  });
  if (!response) {
    throw new Error(response);
  }
  response.success || message.error(response.data.error.message);
  return response;
};

const GetTourForEdit = async (param) => {
  const response = await httpService.get(API.GET_TOUR_FOR_EDIT_PUBLIC, param);
  if (!response) {
    return [];
  }
  return response;
};

// by TourID + postID
const GetPostForEdit = async (param) => {
  const response = await httpService.get(API.GET_POST_FOR_EDIT_PUBLIC, param);
  if (!response) {
    return [];
  }
  return response;
};

// by only postID
const GetPostForEditByPostId = async (param) => {
  const response = await httpService.get(API.GET_POST_FOR_EDIT_BY_POST_ID, param);
  if (!response) {
    return [];
  }
  return response;
};
// const GetCurrentUserPic = async () => {
//   const response = await httpService.get(API.GET_CURRENT_USER_PROFILE_PIC);
//   if (!response) {
//     return [];
//   }
//   return response;
// };
const CreateOrEditPost = async (data) => {
  const response = await httpService.post(API.CREATE_OR_EDIT_POST, data, {
    'content-type': 'application/json'
  });
  if (!response) {
    throw new Error(response);
  }
  return response;
};
const ResetPass = async (data) => {
  const response = await httpService.post(API.RESET_PASSWORD, data);
  if (!response) {
    throw new Error(response);
  }
  return response;
};
const UpdateUserProfile = async (data) => {
  const response = await httpService.put(API.UPDATE_USER_PROFILE, data);
  if (!response) {
    throw new Error(response);
  }
  return response;
};
const UpdateUserPicture = async (data) => {
  const response = await httpService.put(API.UPDATE_USER_PIC, data);
  if (!response) {
    throw new Error(response);
  }
  return response;
};
// const SendUploadPicture = async (form) => {
//   let url = API.UPLOAD_AVATAR;
//   const {
//     params: { fileName, fileType, fileToken },
//     formData,
//     config
//   } = form;
//   if (fileName) {
//     url += `fileName=${encodeURIComponent(`${fileName}`)}&`;
//   }
//   if (fileType) {
//     url += `fileType=${encodeURIComponent(`${fileType}`)}&`;
//   }
//   if (fileToken) {
//     url += `fileToken=${encodeURIComponent(`${fileToken}`)}&`;
//   }
//   url = url.replace(/[?&]$/, '');
//   const response = await httpService.post(url, formData, config);
//   if (!response) {
//     throw new Error(response);
//   }
//   return response;
// };

const UpdateUserPass = async (data) => {
  const response = await httpService.post(API.UPDATE_USER_PASS, data);
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const DeleteUserTour = async (param) => {
  const response = await httpService.delete(API.DELETE_USER_TOUR, param);
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const DeleteUserPost = async (param) => {
  const response = await httpService.delete(API.DELETE_USER_POST, param);
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const GetPublicArticleForView = async (param) => {
  const response = await httpService.get(API.GET_PUBLIC_ARTICLE_FOR_VIEWS, param);
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const GetListTourForEdit = async () => {
  const response = await httpService.get(API.GET_LIST_TOUR_FOR_EDIT);
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const SetNewPass = async (data) => {
  const response = await httpService.post(API.SET_NEW_PASSWORD, data);
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const GetComment = async (data) => {
  const response = await httpService.get(API.GET_COMMENT, data);
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const AddComment = async (data) => {
  const response = await httpService.post(API.POST_COMMENT, data);
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const EditComment = async (data) => {
  const response = await httpService.post(API.EDIT_COMMENT, data);
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const DelComment = async (data) => {
  const response = await httpService.delete(API.DEL_COMMENT, data);
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const CreateMultiPost = async (data) => {
  const response = await httpService.post(API.CREATE_MULTI_POST, data, {
    'content-type': 'application/json'
  });
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const GetPostForView = async (param) => {
  const response = await httpService.get(API.GET_POST_VIEW, param);
  if (!response) {
    return [];
  }
  return response;
};

const GetS3Avatar = async (param) => {
  const response = await httpService.get(API.GET_S3_AVATAR);
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const PutS3Avatar = async ({ url, params, body }) => {
  const response = await httpService.putUrl({ url, params, body });
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const GetLikeEnti = async (data) => {
  const response = await httpService.get(API.GET_LIKE, data);
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const PostLikeEnti = async (data) => {
  const response = await httpService.post(API.POST_LIKE_ENTI, data);
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const PostUnLikeEnti = async (data) => {
  const response = await httpService.post(API.POST_UNLIKE_ENTI, data);
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const GetIsUserLikeEnti = async (data) => {
  const response = await httpService.get(API.GET_IS_LIKE_ENTI, data);
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const GetImgAddon = async (data) => {
  const response = await httpService.get(API.GET_IMG_ADDON);
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const PostImgAddon = async (data) => {
  const response = await httpService.post(API.POST_IMG_ADDON, data);
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const DelImgAddon = async (data) => {
  const response = await httpService.delete(API.DEL_IMG_ADDON, data);
  if (!response) {
    throw new Error(response);
  }
  return response;
};

const CreatePostAndReturnID = async (data) => {
  const response = await httpService.post(API.CREATE_POST_AND_RETURN_ID, data);
  if (!response) {
    throw new Error(response);
  }
  return response;
};

export default {
  Authenticate,
  GetPublicArticle,
  GetPublicPosts,
  GetPublicTours,
  Register,
  GetInforUser,
  GetMyPosts,
  GetMyTours,
  GetCurrentUserProfile,
  // GetCurrentUserPic,
  ResetPass,
  UpdateUserProfile,
  UpdateUserPicture,
  UpdateUserPass,
  // SendUploadPicture,
  GetS3PreSignedUrl,
  PutS3File,
  CreateOrEditTour,
  GetTourForEdit,
  GetPostForEdit,
  GetPostForEditByPostId,
  CreateTourAndReturnId,
  CreatePostAndReturnID,
  DeleteUserTour,
  CreateOrEditPost,
  DeleteUserPost,
  GetPublicArticleForView,
  GetListTourForEdit,
  SetNewPass,
  GetComment,
  AddComment,
  EditComment,
  DelComment,
  CreateMultiPost,
  GetPostForView,
  GetS3Avatar,
  PutS3Avatar,
  GetLikeEnti,
  GetIsUserLikeEnti,
  PostLikeEnti,
  PostUnLikeEnti,
  GetImgAddon,
  PostImgAddon,
  DelImgAddon
};
