import React, { useState } from 'react';
import services from 'services';

const useGetListTourForEdit = () => {
    const [loading, setLoading] = useState(false);
    const [listTour, setListTour] = useState()

    const getListTour = async () => {
        try {
            setLoading(true);
            const response = await services.GetListTourForEdit();
            if (response?.success) {
                const { result: { items } } = response
                setListTour(items)
            }
            return response
        } catch (error) {
            throw new Error(error)
        } finally {
            setLoading(false);
        }
    };
    return {
        listTour,
        loading,
        getListTour
    };
};

export default useGetListTourForEdit;
