import axios from "axios";
import cookie from "js-cookie";
import { ENVIRONMENT } from "./environment";
import { logout } from "./authClient";

const environment = ENVIRONMENT.API;
const instance = axios.create({
  timeout: 25000,
  baseURL: environment,
  headers: { 'content-type': 'application/json' },
});

const request = async ({ ...options }) => {
  const onSuccess = async (response) => {
    return response.data;
  };
  const onError = (error) => {
    // console.log(error)
    return error.response || error.message;
  };
  const token = cookie.get('token') || '';
  if (token) {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // console.log('error',error)
      if (error.response.status === 401) {
        logout()
      }
      return Promise.reject(error);
    },
  );
  return instance(options).then(onSuccess).catch(onError);
};
const get = async (endPoint, params) => {
  const response = await request({
    method: 'GET',
    url: endPoint,
    params: params
  });
  return response;
};
const post = async (endPoint, data, config) => {
  const response = await request({ 
    method: 'POST',
    url: endPoint,
    data,
    headers: config,
  });
  return response;
};
const put = async (endPoint, data) => {
  const response = request({
    method: 'PUT',
    url: endPoint,
    data,
  });

  return response;
};
const _delete = async (endPoint, params) => {
  const response = await request({
    method: 'DELETE',
    url: endPoint,
    params,
  });

  return response;
};
const putUrl = async ({ url, body, params }) => {
  const contentType = `multipart/form-data`
  try {
    const response = await axios({
      method: "put",
      timeout: 25000,
      url: url,
      params: params,
      data: body,
      headers: { "Content-Type": contentType, },
    });
    return response
  } catch (error) {
    return error.response || error.message
  }
}

export const httpService = {
  get,
  post,
  put,
  delete: _delete,
  putUrl
  // postPic
};