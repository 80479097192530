import useGetArticle from "./useGetPublicArticle";
import useGetPublicPosts from "./useGetPublicPosts";
import useGetPublicTours from "./useGetPublicTours";
import useLogin from "./useLogin";
import useRegister from "./useRegister";
import useUploadFilesToS3 from "./useUploadFilesToS3";
import useCreateOrEditTour from "./useCreateOrEditTour";
import useGetMyPosts from "./useGetMyPosts"
import useGetMyTours from "./useGetMyTours";
import useGetProfileUser from "./useGetProfileUser";
import useResetPass from "./useResetPass";
import useUpdateUserProfile from "./useUpdateUserProfile";
import useUpdateUserPic from "./useUpdateUserPic";
import useGetTourForEdit from "./useGetTourForEdit";
import useGetPostForEdit from "./useGetPostForEdit";
import useCreateTourAndReturnId from "./useCreateTourAndReturnId";
import useCreateOrEditPost from "./useCreateOrEditPost";
import useDeleteUserPost from "./useDeleteUserPost";
import useGetPublicArticleForView from "./useGetPublicArticleForView";
import useGetListTourForEdit from "./useGetListTourForEdit";
import useUpdatePassword from "./useUpdatePassword";
import useSetNewPass from "./useSetNewPass";
import useDeleteUserTour from "./useDeleteUserTour";
import useComment from "./useComment";
import useCreateMultiPost from "./useCreateMultiPost";
import useGetPostForView from "./useGetPostForView";
import useLikeEntity from "./useLikeEntity";
import useImgAddon from "./useImgAddon";

export default {
    useGetArticle,
    useGetPublicPosts,
    useGetPublicTours,
    useLogin,
    useRegister,
    useUploadFilesToS3,
    useCreateOrEditTour,
    useGetMyTours,
    useGetMyPosts,
    useGetProfileUser,
    useResetPass,
    useUpdateUserProfile,
    useUpdateUserPic,
    useGetTourForEdit,
    useGetPostForEdit,
    useCreateTourAndReturnId,
    useCreateOrEditPost,
    useDeleteUserPost,
    useDeleteUserTour,
    useGetPublicArticleForView,
    useGetListTourForEdit,
    useUpdatePassword,
    useSetNewPass,
    useComment,
    useCreateMultiPost,
    useGetPostForView,
    useLikeEntity,
    useImgAddon
}