import React, {
    useState,
    useContext,
    createContext,
    useMemo,
    useEffect,
    useRef,
} from 'react';
import useCustomHook from 'hook'
import { EXPLORE } from '@routes/constants';

const { useGetPublicPosts, useGetPublicTours } = useCustomHook

const PublicDataContext = createContext();

export const usePublicDataContext = () => useContext(PublicDataContext);

export const PublicDataProvider = ({ children }) => {
    const [pathName, setPathName] = useState('')
    const { data: posts, getPosts, loading: loadPost, totalCount: totalPosts } = useGetPublicPosts()
    const { data: tours, getTours, loading: loadTour, totalCount: totalTours } = useGetPublicTours()
    let { current: result } = useRef()

    const handleData = ({ param, ...rest }) => {
        setPathName(param)
        if (param === EXPLORE.photos) {
            getPosts(rest)
        } else if (param === EXPLORE.tours) {
            getTours(rest)
        }
    }

    const { result: data } = useMemo(() => {
        switch (pathName) {
            case EXPLORE.photos:
                result = {
                    name: EXPLORE.photos,
                    items: posts,
                    count: totalPosts,
                    loading: loadPost
                };
                break;
            case EXPLORE.tours:
                result = {
                    name: EXPLORE.tours,
                    items: tours,
                    count: totalTours,
                    loading: loadTour
                };
                break;
            default:
                break;
        }
        return {
            result
        }
    }, [pathName, posts, tours, pathName, loadPost, loadTour])

    const publicDataContextValue = useMemo(
        () => ({
            data,
            getPosts,
            getTours,
            setPathName,
            handleData,
            totalPosts,
            totalTours
        }),
        [data, loadPost, loadTour],
    );

    return (
        <PublicDataContext.Provider value={publicDataContextValue}>
            {children}
        </PublicDataContext.Provider>
    );
};