import { AddonType, Position, Privacy, HotspotActionType } from 'interfaces/PostHandlingInterface';
import React, { useState } from 'react'
import services from 'services'

export interface CreateOrEditPostData {
  id?: number;
  url: string;
  thumbnailUrl: string;
  privacy: Privacy;
  name: string;
  description: string;
  isShow: boolean;
  isFeature: boolean;
  configs: {
    initialViewPosition: Position;
    initialZoom: number;
    zoomRange: [number, number];
  };
  addons: Array<{
    type?: AddonType;
    configs: {
      displayValue: string;
      width: number;
      height?: number;
      opacity?: number;
      action?: { type?: HotspotActionType; target?: string };
      position?: Position;
      fontSize?: number;
      label?: string;
    }
  }>
}
const useCreateOrEditPost = () => {
  const [loading, setLoading] = useState(false);

  const createOrEditPost = async (data: CreateOrEditPostData) => {
    try {
      setLoading(true);
      const handledData = {
        ...data,
        configs: JSON.stringify(data.configs),
        addons: data.addons?.map(addon => {
          return {
            ...addon,
            configs: JSON.stringify(addon.configs),
          }
        })
      }
      const result = await services.CreateOrEditPost(handledData)
      return result
    } catch (error) {
      throw new Error(error)
    } finally {
      setLoading(false);
    }
  }

  const createPostReturnID = async (data: any) => {
    try {
      setLoading(true);
      const handledData = {
        ...data,
        configs: JSON.stringify(data.configs),
      }
      const result = await services.CreatePostAndReturnID(handledData)
      return result
    } catch (error) {
      throw new Error(error)
    } finally {
      setLoading(false);
    }
  }

  return {
    loading,
    createOrEditPost,
    createPostReturnID
  }
}

export default useCreateOrEditPost