import React, { useState } from 'react'
import services from 'services'

const useLogin = () => {
    const [loading, setLoading] = useState(false);

    const loginUser = async (form: any) => {
        try {
            setLoading(true);
            const response = await services.Authenticate(form);
            return response
        } catch (error) {
            throw new Error(error)
            // console.log('error', error);
        } finally {
            setLoading(false);
        }
    }
    return {
        loading,
        loginUser
    }
}

export default useLogin