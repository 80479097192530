import React, { useState } from 'react'
import services from 'services';

const useGetPublicPosts = () => {
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState([]);

    const getPosts = async (param: any) => {
        try {
            setLoading(true);
            const response = await services.GetPublicPosts(param);;
            if (response?.success) {
                const { result: { items, totalCount } } = response
                setTotalCount(totalCount);
                setData(items);
            }
            return response
        } catch (error) {
            throw new Error(error)
        } finally {
            setLoading(false);
        }
    }
    return {
        loading,
        data,
        totalCount,
        setTotalCount,
        getPosts
    }
}

export default useGetPublicPosts