import React, { useState } from 'react'
import services from 'services'

const useUploadFilesToS3 = () => {
    const [loading, setLoading] = useState(false);

    const uploadFilesToS3 = async (files: any[]): Promise<{
        isSuccess: boolean;
        fileUrls?: string[]
    }> => {
        try {
            setLoading(true);
            let fileUrls = [];
            const uniqueFileNames = files.map((file, index) => {
                const uniqueFileName = `${new Date().valueOf()}${index}_${file.name.replace(/\s/g, '_')}`
                return uniqueFileName
            }).reduce((nameAccumulator, currentName, index, array) => nameAccumulator.concat(currentName).concat(index !== array.length - 1 ? ';' : ''), '')
            const preSignedUrls: string[] = (await services.GetS3PreSignedUrl({ fileNames: uniqueFileNames })).result
            const promisedPutS3s = preSignedUrls?.map(async (preSignedUrl, index) => {
                const fullUrl = new URL(preSignedUrl)
                const url = fullUrl.origin.concat(fullUrl.pathname)
                fileUrls.push(url)
                const params = Object.fromEntries(fullUrl.searchParams);
                const binaryFile = files[index]
                return services.PutS3File({ url, params, body: binaryFile })
            })
            let result: any = {};
            await Promise.all(promisedPutS3s).then((_values) => {
                result = {
                    isSuccess: true,
                    fileUrls
                }
            })
            return result;
        } catch (error) {
            return { isSuccess: false }
        } finally {
            setLoading(false);
        }
    }
    return {
        loading,
        uploadFilesToS3
    }
}

export default useUploadFilesToS3