import React from 'react'
import services from 'services';

const useGetMyTours = () => {
    const [loading, setLoading] = React.useState(false);
    const [totalCount, setTotalCount] = React.useState(0);
    const [data, setData] = React.useState([]);

    const getTours = async (param: any) => {
        try {
            setLoading(true);
            const response = await services.GetMyTours(param);
            if (response?.success) {
                const { result: { items, totalCount } } = response
                setTotalCount(totalCount);
                setData(items);
            }
            return response
        } catch (error) {
            throw new Error(error)
        } finally {
            setLoading(false);
        }
    }
    return {
        loading,
        data,
        totalCount,
        setTotalCount,
        getTours
    }
}

export default useGetMyTours