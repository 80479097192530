import React, { useState } from 'react'
import services from 'services'

const useLikeEntity = () => {
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(null)
    const [isUserLike, setUserLike] = useState(false)

    const getLikeEnti = async (data: any) => {
        try {
            setLoading(true);
            const response = await services.GetLikeEnti({ ...data, entityEnum: 'Post' });
            response?.success && setTotalCount(response.result)
            return response
        } catch (error) {
            throw new Error(error)
        } finally {
            setLoading(false);
        }
    }

    const postLikeEnti = async (data: any) => {
        try {
            setLoading(true);
            const response = await services.PostLikeEnti({ ...data, entity: 'Post' });
            return response
        } catch (error) {
            throw new Error(error)
        } finally {
            setLoading(false);
        }
    }

    const postUnlikeEnti = async (data: any) => {
        try {
            setLoading(true);
            const response = await services.PostUnLikeEnti({ ...data, entity: 'Post' });
            return response
        } catch (error) {
            throw new Error(error)
        } finally {
            setLoading(false);
        }
    }

    const getIsUserLikeEnti = async (data: any) => {
        try {
            setLoading(true);
            const response = await services.GetIsUserLikeEnti({ ...data, entityEnum: 'Post' });
            response?.success && setUserLike(response.result)
            return response
        } catch (error) {
            throw new Error(error)
        } finally {
            setLoading(false);
        }
    }

    return {
        loading,
        totalCount,
        isUserLike,
        getLikeEnti,
        getIsUserLikeEnti,
        postLikeEnti,
        postUnlikeEnti
    }
}

export default useLikeEntity