import React, { useState } from 'react'
import services from 'services'

const useUpdatePassword = () => {
    const [loading, setLoading] = useState(false);

    const updateUserPass = async (form: any) => {
        try {
            setLoading(true);
            const response = await services.UpdateUserPass(form)
            return response
        } catch (error) {
            throw new Error(error)
        } finally {
            setLoading(false);
        }
    }
    return {
        loading,
        updateUserPass
    }
}

export default useUpdatePassword