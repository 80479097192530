import React from 'react'
import { Row, Col, Image } from 'antd'
import { RiDoubleQuotesL } from 'react-icons/ri'
import './style.less'

const data = [
    {
        name: "Ben Claremont",
        comment: "Kuula stands head and shoulders above the rest. It’s a perfect mix of professional features, a super easy to use interface and the price is one of the",
        avatar: "/img/about/Ben.svg",
        about: "Vitual Tour Pro"
    },
    {
        name: "Micheal Ty",
        comment: "Kuula stands head and shoulders above the rest. It’s a perfect mix of professional features, a super easy to use interface and the price is one of the",
        avatar: "/img/about/Michale.svg",
        about: "Vitual Tour Pro"
    },
    {
        name: "Ben Claremont",
        comment: "Kuula stands head and shoulders above the rest. It’s a perfect mix of professional features, a super easy to use interface and the price is one of the",
        avatar: "/img/about/Ben.svg",
        about: "Vitual Tour Pro"
    }
]

const UsersChatCore = () => {
    const renderItems = React.useMemo(() => {
        return (
            <Row className='usersCore'>
                <Col span={24} className='title'>
                    <h1>What users say about Skydev</h1>
                </Col>
                <Col span={24} className='group'>
                    {data?.map((data, index) => {
                        return (
                            <Col key={index} className='group-item'>
                                <RiDoubleQuotesL />
                                <span>{data.comment}</span>
                                <Col className='user-info'>
                                    <h1>{data.name}</h1>
                                    <p>{data.about}</p>
                                    <img src={data.avatar} loading='lazy' />
                                </Col>
                            </Col>
                        )
                    })}
                </Col>
            </Row>
        )
    }, [])
    
    return (
        <React.Fragment>
            {renderItems}
        </React.Fragment>
    )
}

export default UsersChatCore