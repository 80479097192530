import React, { useState } from 'react'
import services from 'services'

const useDeleteUserPost = () => {
    const [loading, setLoading] = useState(false)
    const deleteUserPost = async (param: any) => {
        try {
            setLoading(true)
            const response = await services.DeleteUserPost(param)
            return response
        } catch (error) {
            throw new Error(error)
        } finally {
            setLoading(false)
        }
    }

    return {
        loading,
        deleteUserPost
    }
}

export default useDeleteUserPost