import React from 'react'
import { Col, Row } from 'antd'
import CanvasLayout from '@components/Canvas/CanvasLayout'
import './style.less'

const imgData = {
    image: "./360Home.jpeg"
}
const HeaderImgCore = () => {
    const renderImg = React.useMemo(() => {
        return (
            <Row className='backgroundCore'>
                <Col span={24} className='img'></Col>
                {/* <Col span={24} style={{ height: '50vh' }}>
                <CanvasLayout data={{ value: imgData }} />
            </Col> */}
                <Col span={14} className='content'>
                    <h1>Create Virtual Tours that engage your audience</h1>
                </Col>
            </Row>
        )
    }, [])
    return (
        <React.Fragment>
            {renderImg}
        </React.Fragment>
    )
}

export default HeaderImgCore