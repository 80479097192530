import React, { useState } from 'react'
import services from 'services'

const useCreateMultiPost = () => {
  const [loading, setLoading] = useState(false);

  const createMultiPost = async (data: any[]) => {
    try {
      setLoading(true);
      const handledData = data?.map((post)=>{
          return {
            ...post,
            configs: JSON.stringify(post.configs)
          }        
      })
      const response = await services.CreateMultiPost(handledData)
      return response
    } catch (error) {
      throw new Error(error)
    } finally {
      setLoading(false);
    }
  }
  return {
    loading,
    createMultiPost
  }
}

export default useCreateMultiPost