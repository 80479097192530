import React, {
  useState,
  useContext,
  createContext,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useLayoutEffect
} from 'react';
import cookie from 'js-cookie';
import { setToken, setTokenUser, getTokenUser } from '@services/authClient';
import useCustomHook from 'hook';
import services from 'services';

const { useLogin } = useCustomHook
const dataUser = getTokenUser();
const tokenUser = cookie.get('token')
export const isAuthen = dataUser && tokenUser

const initialState = dataUser
  ? {
    data: dataUser,
    error: null,
    isAuth: isAuthen
  }
  : {
    data: null,
    error: null,
    isAuth: isAuthen
  };

const useSafeDispatch = (dispatch) => {
  const mounted = useRef(false);
  useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  return useCallback(
    (...args) => (mounted.current ? dispatch(...args) : null),
    [dispatch],
  );
};

const useAsync = () => {
  const [{ data, error }, setState] = React.useReducer(
    (s, a) => ({
      ...s,
      ...a,
    }),
    initialState,
  );
  const safeSetState = useSafeDispatch(setState);
  const setData = React.useCallback(
    (data) =>
      safeSetState({
        data,
      }),
    [safeSetState],
  );
  return {
    error,
    data,
    setData,
  };
};

const AuthContext = createContext();

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(dataUser || {});
  const { setData, data } = useAsync();
  const { loginUser, loading } = useLogin();
  const [isAuthenticated, setAuthenticated] = useState(initialState.isAuth)

  const login = useCallback(
    async (form) => {
      const response = await loginUser(form);
      if (response?.success) {
        const { result: { accessToken } } = response;
        setToken({ token: accessToken });
        const {
          result: { user },
        } = await services.GetInforUser();
        setData(user);
        setTokenUser(user);
        setAuthenticated(true)
      }
      return response
    },
    [loginUser, setData],
  );

  useEffect(() => {
    setUser(data ? { ...data } : {});
  }, [data]);

  const logout = useCallback(() => {
    cookie.remove('token');
    cookie.remove('user');
    setData({});
    setAuthenticated(false)
  }, [setData]);

  useEffect(() => {
    !isAuthen && logout()
  }, [isAuthen])

  const authContextValue = useMemo(
    () => ({
      login,
      logout,
      user,
      loading,
      isAuthenticated
    }),
    [login, logout, user, loading, isAuthenticated],
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
