import React, { Suspense, useMemo } from 'react'
const MainCanvas = React.lazy(() => import('./MainCanvas'));

const CanvasLayout = ({ data }) => {

    return (
        <Suspense fallback={<h1>Loading.....</h1>}>
            <MainCanvas data={data} />
        </Suspense>
    )
}

export default CanvasLayout