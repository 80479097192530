import React, { useState } from 'react'
import services from 'services'

const useRegister = () => {
    const [loading, setLoading] = useState(false);

    const register = async (form: any) => {
        try {
            setLoading(true);
            const response = await services.Register(form)
            return response
        } catch (error) {
            throw new Error(error)
        } finally {
            setLoading(false);
        }
    }
    return {
        loading,
        register
    }
}

export default useRegister