import { Button } from 'antd';
import { useAuthContext } from 'context/AuthContext';
import { NavLink } from 'react-router-dom'

export const PAGE_ROUTE = {
    EXPLORE: "explore",
    ABOUT: "about",
    BLOG: "blog",
    BLOG_DETAIL: "blog/detail",
    TOUR: "tour",
    CREATE: "create",
    EXIST: "exist",
    BATCH: "batch",
    EDIT: "edit",
    POST: "post",
    PROFILE: "profile",
    SETTING_PROFILE: "setting-profile",
    LOGIN: "login",
    REGISTER: "register",
    RESET_PASS_REQUEST: "reset-pass-request",
    SET_NEW_PASS: "account/reset-password",
    RESET_PASS_SUCCESS: "reset-success",
    UPLOAD: "upload",
    VIRTUAL_EVENT: 'virtual-event'
}

export const MY_PROFILE = {
    photos: 'my_photos',
    tours: 'my_tours',
}
export const EXPLORE = {
    photos: 'explore_photos',
    tours: 'explore_tours',
    most_liked: 'explore_most_liked',
    collections: 'explore_collections'
}
export const SETTING_PROFILE = {
    edit: 'edit',
    changePass: 'change_password'
}
export const SETTING_TOUR = {
    posts: 'posts',
    setting: 'tourSettings'
}
const ANY_ROUTE = [
    {
        label:
            <NavLink to={`/${PAGE_ROUTE.EXPLORE}`} >
                <span>Explore</span>
            </NavLink>,
        key: '1',
        // icon: null,
    },
    {
        label:
            <NavLink to={`/${PAGE_ROUTE.ABOUT}`} >
                <span>About</span>
            </NavLink>,
        key: '2',
        // icon: null,
    },
    {
        label:
            <NavLink to={`/${PAGE_ROUTE.BLOG}`} >
                <span>Blog</span>
            </NavLink>,
        key: '3',
        // icon: null,
    },
]
const PUBLIC_ROUTE = [
    {
        label:
            <NavLink to={`/${PAGE_ROUTE.LOGIN}`} >
                <span>Sign In</span>
            </NavLink>,
        key: '8',
        // icon: null,
    },
    {
        label:
            <NavLink to={`/${PAGE_ROUTE.REGISTER}`} >
                <span>Register</span>
            </NavLink>,
        key: '9',
        // icon: null,
    }
]
const PRIVATE_ROUTE = [
    {
        label:
            <Button type='text' style={{ padding: '0' }} >
                <span>Upload</span>
            </Button>,
        key: 'sub1',
        icon: null,
        children: [
            {
                label:
                    <NavLink to={`/${PAGE_ROUTE.TOUR}/${PAGE_ROUTE.CREATE}`} >
                        <span>Create Tour</span>
                    </NavLink>,
                key: '4',
                icon: null
            },
            {
                label:
                    <NavLink to={`/${PAGE_ROUTE.UPLOAD}`}>
                        <span>Single Image</span>
                    </NavLink>,
                key: '5',
                icon: null
            }
        ]
    },
    {
        label:
            <NavLink to={`/${PAGE_ROUTE.PROFILE}`} >
                <span>Profile</span>
            </NavLink>,
        key: 'sub2',
        icon: null,
        children: [
            {
                label:
                    <NavLink to={`/${PAGE_ROUTE.PROFILE}/${MY_PROFILE.photos}`} >
                        <span>My Photos</span>
                    </NavLink>,
                key: '6',
                icon: null
            },
            {
                label:
                    <NavLink to={`/${PAGE_ROUTE.PROFILE}/${MY_PROFILE.tours}`}>
                        <span>My Tours</span>
                    </NavLink>,
                key: '7',
                icon: null
            }
        ]
    },

]

export const useNavItems = () => {
    const { isAuthenticated } = useAuthContext()
    let data = []

    if (isAuthenticated) {
        data = [
            ...ANY_ROUTE,
            ...PRIVATE_ROUTE
        ]
    } else {
        data = [
            ...ANY_ROUTE,
            ...PUBLIC_ROUTE
        ]
    }
    return data
}